<ng-container *ngIf="myTabStateService.TabStates$ | async as TabStates">
    <mat-accordion>
        <mat-expansion-panel
                class="mat-elevation-z0"
                hideToggle
                (opened)="onTabOpen('bde1')"
                (closed)="onTabClose('bde1')"
                [expanded]="TabStates.bde1 == null || TabStates.bde1 == true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="flex space-x-2">
                        <div class="ml-1">
                            <div class="transition-all rotate-0 {{ TabStates.bde1 ? 'rotate-90' : '' }}">
                                <fa-icon icon="chevron-right"></fa-icon>
                            </div>
                        </div>
                        <div class="text-xl font-bold">
                            {{ 'tx-bde.assignment' | translate }}
                        </div>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                <form role="form" #bdeFormCtrl="ngForm">
                    <fieldset>
                        <div class="grid">
                            <div class="col-12">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="outline" >
                                        <mat-label>{{ 'tx-bde.selectAssignment' | translate }}</mat-label>
                                        <mat-select #auftragSelect
                                                name="myGuiAuftragDropdown"
                                                id="myGuiAuftragDropdown"
                                                [(ngModel)]="mycurrentAuftragSelectID"
                                                (selectionChange)="onAuftragSelected($event)"
                                                [disabled]="showLoadingBDEData">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="searchControlAuftrag" (keyup)="onSearchAuftrag($event)"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let auftrag of filteredAuftragsArray" [value]="auftrag.value">
                                                {{ auftrag.label }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="form-group" *ngIf="myGuiAuftragsschrittDropdownVisible">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-select #contractSelect
                                                    name="myGuiAuftragsschrittDropdown"
                                                    id="myGuiAuftragsschrittDropdown"
                                                    placeholder="Bitte Tätigkeit wählen"
                                                    [(ngModel)]="myCurrentAuftragsschrittSelectID"
                                                    (selectionChange)="onAuftragSchrittSelected($event)"
                                                    [disabled]="showLoadingBDEData">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="searchControlAuftragsschritt"
                                                                       (keyup)="onSearchAuftragsschritt($event)">
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let auftragsschritt of filteredAuftragsschrittArray"
                                                        [value]="auftragsschritt.value">
                                                {{ auftragsschritt.label }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="grid">
                                    <div [ngClass]="(isMobile || isTablet) ? 'col-6' : 'col-12'">
                                        <button
                                                mat-raised-button
                                                color="primary"
                                                [disabled]="!myGuiTaetigkeitDropdownVisible || !myGuiAuftragsschrittDropdownVisible"
                                                (click)="doStempelBDE('AB')"
                                                class="btn btn-lg btn-primary btn-block">
                                            {{ 'tx-bde.startAssignment' | translate }}
                                        </button>
                                    </div>
                                    <div [ngClass]="currentSession.barcode == 1 && (isMobile || isTablet) ? 'col-6' : 'col-12'">
                                        <button
                                                mat-raised-button
                                                color="primary"
                                                [disabled]="!((isMobile || isTablet) && barcodeState !== 0)"
                                                (click)="showCodeScanner()"
                                                class="btn btn-lg btn-primary btn-block">
                                            Scan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <!--<section class="widget waiting active-bde" *ngIf="showLoadingBDEData">
        <div class="row title">
            <div class="col-xs-12">
                <h4 class="title">{{ 'tx-bde.pleaseWait' | translate }}</h4>
            </div>
        </div>
        <div class="row title" *ngIf="!isOnline">
            <div class="col-xs-12">
                <h5 class="subtitle">
                    Die App ist gerade offline, die Daten werden übermittelt, wenn die App wieder online ist.
                </h5>
            </div>
        </div>
        <div class="widget-body">
            <div class="row">
                <div class="col-xs-12">
                    <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
                    <h6>{{ 'tx-bde.data' | translate }}</h6>
                </div>
            </div>
        </div>
    </section>-->

    <div *ngIf="myActiveBDE$ | async as myActiveBDE" class="mt-2">
        <section class="widget active-bde" widget *ngFor="let item of myActiveBDE; let i = index">
            <tx-bde-assignment
                    [assignment]="item"
                    [i]="i"
                    (end)="
				this.doStempelBDE(
					$event.aBuchungsart,
					$event.aActiveAuftrag,
					$event.aActiveSchritt,
					$event.aIndex,
					$event.aKill,
					$event.note,
					$event.quantity
				)
			"></tx-bde-assignment>
        </section>
        <section class="widget active-bde" widget *ngFor="let item of myQueuedBDE; let i = index">
            <tx-bde-assignment
                    [assignment]="item"
                    [i]="1000 + i"
                    (end)="
				this.doStempelBDE(
					$event.aBuchungsart,
					$event.aActiveAuftrag,
					$event.aActiveSchritt,
					$event.aIndex,
					$event.aKill,
					$event.note
				)
			"></tx-bde-assignment>
        </section>
    </div>
</ng-container>
