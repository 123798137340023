<div class="">
	<main class="widget-login-container" id="content" role="main">
		<div class="grid">
			<div class="col-12 md:col-6 xl:col-4 md:col-offset-3 xl:col-offset-4">
				<h5 [ngClass]="pinModeState ? 'pin-mode-logo' : ''" class="pl-3 widget-login-logo animated fadeInUp">
					<img alt="{{config?.name}}" class="img-responsive" src="{{config?.logoDark}}" />
					<button (click)="toggleShowPinLoginCard()" *ngIf="pinModeState" class="pin-login-switch">
						<span *ngIf="!showPinLoginCard" class="pin-login-switch__pin">
							<svg height="50px" version="1.1" viewBox="0 0 46 50" width="46px" xmlns="http://www.w3.org/2000/svg">
								<g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1">
									<g fill="#ffffff" fill-rule="nonzero" id="Artboard" transform="translate(-226.000000, -53.000000)">
										<g id="flaticon1558017191-svg" transform="translate(226.000000, 53.000000)">
											<path
												d="M45.5244108,28.1560606 C44.7139731,23.3257576 41.1304714,22.8957912 39.8661616,22.8957912 C39.5924242,22.8957912 39.4388889,22.9159933 39.4388889,22.9159933 C38.3491582,20.7361953 36.7176768,20.1910774 35.3505051,20.1910774 C33.9829966,20.1909091 32.8993266,20.7361953 32.8993266,20.7361953 C31.8092593,18.5560606 30.1397306,18.0109428 28.7513468,18.0109428 C27.362963,18.0107744 26.2580808,18.5557239 26.2580808,18.5557239 L26.2580808,12.7885522 C26.2580808,10.5993266 24.6171717,9.50488215 22.9750842,9.50488215 C21.3333333,9.50488215 19.6924242,10.5993266 19.6924242,12.7885522 C19.6924242,17.1675084 19.6924242,27.2765993 19.6924242,27.2765993 C12.1166667,29.1249158 19.0190236,40.4986532 21.2075758,43.9203704 L21.2075758,48.1200337 C21.2075758,49.0459596 22.1289562,49.8877104 23.0548822,49.8877104 L40.3949495,49.8877104 C41.3208754,49.8877104 41.9146465,49.0459596 41.9146465,48.1200337 L41.9146465,44.1358586 C45.9550505,37.826431 46.220202,32.3005051 45.5244108,28.1560606 Z"
												id="Path"></path>
											<circle cx="22.9284512" cy="3.3003367" id="Oval" r="3.3003367"></circle>
											<circle cx="13.0276094" cy="12.8052189" id="Oval" r="3.3003367"></circle>
											<circle cx="13.0276094" cy="3.3003367" id="Oval" r="3.3003367"></circle>
											<circle cx="3.32474747" cy="12.8052189" id="Oval" r="3.3003367"></circle>
											<circle cx="3.32474747" cy="3.3003367" id="Oval" r="3.3003367"></circle>
											<circle cx="13.0276094" cy="22.3099327" id="Oval" r="3.3003367"></circle>
											<circle cx="3.32474747" cy="22.3099327" id="Oval" r="3.3003367"></circle>
										</g>
									</g>
								</g>
							</svg>
						</span>
						<span *ngIf="showPinLoginCard" class="pin-login-switch__input">
							<svg height="28px" version="1.1" viewBox="0 0 50 28" width="50px" xmlns="http://www.w3.org/2000/svg">
								<g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1">
									<g fill="#fff" fill-rule="nonzero" id="Artboard" transform="translate(-103.000000, -66.000000)">
										<g id="flaticon1558017040-svg" transform="translate(103.000000, 66.000000)">
											<path
												d="M44.1569892,16.0721505 L14.8298925,16.0721505 C11.6156989,16.0721505 9.00075269,18.6870968 9.00075269,21.9012903 C9.00075269,25.1154839 11.6156989,27.7304301 14.8298925,27.7304301 L44.1569892,27.7304301 C47.3711828,27.7304301 49.986129,25.1154839 49.986129,21.9012903 C49.986129,18.6870968 47.3711828,16.0721505 44.1569892,16.0721505 Z M44.1569892,26.1175269 L14.8298925,26.1175269 C12.5050538,26.1175269 10.6136559,24.226129 10.6136559,21.9012903 C10.6136559,19.5764516 12.5050538,17.6850538 14.8298925,17.6850538 L44.1569892,17.6850538 C46.481828,17.6850538 48.3732258,19.5764516 48.3732258,21.9012903 C48.3732258,24.226129 46.481828,26.1175269 44.1569892,26.1175269 Z"
												id="Shape"></path>switch__input
											<path
												d="M18.9208602,20.3622581 C18.698172,19.9764516 18.2047312,19.8443011 17.8192473,20.0670968 L16.9567742,20.5650538 L16.9567742,19.5692473 C16.9567742,19.123871 16.5958065,18.7627957 16.1503226,18.7627957 C15.7048387,18.7627957 15.343871,19.123871 15.343871,19.5692473 L15.343871,20.5650538 L14.4813978,20.0670968 C14.0954839,19.8443011 13.6023656,19.9764516 13.3797849,20.3622581 C13.1570968,20.747957 13.2892473,21.2411828 13.6749462,21.463871 L14.5374194,21.961828 L13.6749462,22.4597849 C13.2892473,22.6824731 13.1570968,23.1756989 13.3797849,23.5613978 C13.5292473,23.8201075 13.8002151,23.9647312 14.0789247,23.9647312 C14.2156989,23.9647312 14.3545161,23.9298925 14.4813978,23.8565591 L15.343871,23.3586022 L15.343871,24.3544086 C15.343871,24.7997849 15.7048387,25.1608602 16.1503226,25.1608602 C16.5958065,25.1608602 16.9567742,24.7997849 16.9567742,24.3544086 L16.9567742,23.3586022 L17.8192473,23.8565591 C17.9463441,23.9298925 18.0849462,23.9647312 18.2217204,23.9647312 C18.5004301,23.9647312 18.7715054,23.8201075 18.9208602,23.5613978 C19.1435484,23.1756989 19.0113978,22.6824731 18.6256989,22.4597849 L17.7632258,21.961828 L18.6256989,21.463871 C19.0113978,21.2411828 19.1435484,20.747957 18.9208602,20.3622581 Z"
												id="Path"></path>
											<path
												d="M26.4225806,20.3622581 C26.2,19.9764516 25.7064516,19.8443011 25.3209677,20.0670968 L24.4584946,20.5650538 L24.4584946,19.5691398 C24.4584946,19.1237634 24.0975269,18.7626882 23.652043,18.7626882 C23.2065591,18.7626882 22.8455914,19.1237634 22.8455914,19.5691398 L22.8455914,20.5649462 L21.9831183,20.0669892 C21.5970968,19.8441935 21.1039785,19.9764516 20.8815054,20.3621505 C20.6588172,20.7478495 20.7909677,21.2410753 21.1766667,21.4637634 L22.0391398,21.9617204 L21.1766667,22.4596774 C20.7909677,22.6823656 20.6588172,23.1755914 20.8815054,23.5612903 C21.0308602,23.82 21.3019355,23.9646237 21.5806452,23.9646237 C21.7174194,23.9646237 21.8562366,23.9297849 21.9831183,23.8564516 L22.8455914,23.3584946 L22.8455914,24.3543011 C22.8455914,24.7996774 23.2065591,25.1607527 23.652043,25.1607527 C24.0975269,25.1607527 24.4584946,24.7996774 24.4584946,24.3543011 L24.4584946,23.3583871 L25.3209677,23.8563441 C25.4480645,23.9296774 25.5866667,23.9645161 25.7234409,23.9645161 C26.0021505,23.9645161 26.2732258,23.8197849 26.4225806,23.5611828 C26.6452688,23.1754839 26.5131183,22.6822581 26.1274194,22.4595699 L25.2649462,21.9616129 L26.1274194,21.4636559 C26.5131183,21.2412903 26.6452688,20.7480645 26.4225806,20.3622581 Z"
												id="Path"></path>
											<path
												d="M33.924086,20.3622581 C33.7015054,19.9764516 33.2080645,19.8443011 32.8224731,20.0670968 L31.96,20.5650538 L31.96,19.5691398 C31.96,19.1237634 31.5990323,18.7626882 31.1535484,18.7626882 C30.7080645,18.7626882 30.3470968,19.1237634 30.3470968,19.5691398 L30.3470968,20.5649462 L29.4846237,20.0669892 C29.0986022,19.8441935 28.6055914,19.9764516 28.3830108,20.3621505 C28.1603226,20.7478495 28.2924731,21.2410753 28.678172,21.4637634 L29.5406452,21.9617204 L28.678172,22.4596774 C28.2924731,22.6823656 28.1603226,23.1755914 28.3830108,23.5612903 C28.5323656,23.82 28.8034409,23.9646237 29.0821505,23.9646237 C29.2189247,23.9646237 29.3577419,23.9297849 29.4846237,23.8564516 L30.3470968,23.3584946 L30.3470968,24.3543011 C30.3470968,24.7996774 30.7080645,25.1607527 31.1535484,25.1607527 C31.5990323,25.1607527 31.96,24.7996774 31.96,24.3543011 L31.96,23.3583871 L32.8224731,23.8563441 C32.9495699,23.9296774 33.088172,23.9645161 33.2249462,23.9645161 C33.5036559,23.9645161 33.7747312,23.8197849 33.924086,23.5611828 C34.1467742,23.1754839 34.0146237,22.6822581 33.6289247,22.4595699 L32.7664516,21.9616129 L33.6289247,21.4636559 C34.0146237,21.2412903 34.1468817,20.7480645 33.924086,20.3622581 Z"
												id="Path"></path>
											<path
												d="M41.4256989,20.3622581 C41.2031183,19.9764516 40.7096774,19.8443011 40.324086,20.0670968 L39.4616129,20.5650538 L39.4616129,19.5692473 C39.4616129,19.123871 39.1006452,18.7627957 38.6551613,18.7627957 C38.2096774,18.7627957 37.8487097,19.123871 37.8487097,19.5692473 L37.8487097,20.5651613 L36.9862366,20.0672043 C36.6003226,19.8444086 36.1072043,19.9766667 35.8846237,20.3623656 C35.6619355,20.7480645 35.794086,21.2412903 36.1797849,21.4639785 L37.0422581,21.9619355 L36.1797849,22.4598925 C35.794086,22.6825806 35.6619355,23.1758065 35.8846237,23.5615054 C36.0339785,23.8202151 36.3050538,23.9648387 36.5837634,23.9648387 C36.7205376,23.9648387 36.8593548,23.93 36.9862366,23.8566667 L37.8487097,23.3587097 L37.8487097,24.3546237 C37.8487097,24.8 38.2096774,25.1610753 38.6551613,25.1610753 C39.1006452,25.1610753 39.4616129,24.8 39.4616129,24.3546237 L39.4616129,23.3588172 L40.324086,23.8567742 C40.4511828,23.9301075 40.5897849,23.9649462 40.7265591,23.9649462 C41.0052688,23.9649462 41.2763441,23.8202151 41.4256989,23.5616129 C41.6483871,23.175914 41.5162366,22.6826882 41.1305376,22.46 L40.2680645,21.962043 L41.1305376,21.464086 C41.5162366,21.2412903 41.6483871,20.7480645 41.4256989,20.3622581 Z"
												id="Path"></path>
											<path
												d="M6.29892473,20.3678495 L6.26591398,20.3678495 L6.26591398,19.6882796 C6.26591398,18.2075269 5.0611828,17.0026882 3.58032258,17.0026882 C2.09946237,17.0026882 0.894946237,18.2075269 0.894946237,19.6882796 L0.894946237,20.3678495 L0.861935484,20.3678495 C0.416451613,20.3678495 0.055483871,20.7289247 0.055483871,21.1743011 L0.055483871,25.9933333 C0.055483871,26.4387097 0.416451613,26.7997849 0.861935484,26.7997849 L6.29892473,26.7997849 C6.7444086,26.7997849 7.10537634,26.4387097 7.10537634,25.9933333 L7.10537634,21.1743011 C7.10537634,20.7289247 6.7444086,20.3678495 6.29892473,20.3678495 Z M2.50784946,19.6882796 C2.50784946,19.0968817 2.98903226,18.6155914 3.58053763,18.6155914 C4.17204301,18.6155914 4.65322581,19.0967742 4.65322581,19.6882796 L4.65322581,20.3678495 L2.50784946,20.3678495 L2.50784946,19.6882796 Z M5.49247312,25.1868817 L1.6683871,25.1868817 L1.6683871,21.9807527 L5.49247312,21.9807527 L5.49247312,25.1868817 Z"
												id="Shape"></path>
											<path
												d="M14.8298925,11.7635484 L44.1569892,11.7635484 C47.3711828,11.7635484 49.986129,9.14860215 49.986129,5.9344086 C49.986129,2.72021505 47.3711828,0.105268817 44.1569892,0.105268817 L14.8298925,0.105268817 C11.6156989,0.105268817 9.00075269,2.72021505 9.00075269,5.9344086 C9.00075269,9.14860215 11.6158065,11.7635484 14.8298925,11.7635484 Z M14.8298925,1.71817204 L44.1569892,1.71817204 C46.481828,1.71817204 48.3732258,3.60956989 48.3732258,5.9344086 C48.3732258,8.25924731 46.481828,10.1506452 44.1569892,10.1506452 L14.8298925,10.1506452 C12.5050538,10.1506452 10.6136559,8.25924731 10.6136559,5.9344086 C10.6136559,3.60956989 12.5050538,1.71817204 14.8298925,1.71817204 Z"
												id="Shape"></path>
											<path
												d="M0.806451613,10.1013978 L6.24344086,10.1013978 C6.68892473,10.1013978 7.04989247,9.74032258 7.04989247,9.29494624 L7.04989247,8.9555914 C7.04989247,7.76505376 6.45602151,6.7111828 5.54967742,6.07268817 C5.93225806,5.61473118 6.16301075,5.02580645 6.16301075,4.38387097 C6.16301075,2.92924731 4.97956989,1.74591398 3.52494624,1.74591398 C2.07043011,1.74591398 0.886989247,2.92924731 0.886989247,4.38387097 C0.886989247,5.02580645 1.11774194,5.61473118 1.50032258,6.07268817 C0.593870968,6.7111828 0,7.76494624 0,8.9555914 L0,9.29494624 C0,9.74032258 0.360967742,10.1013978 0.806451613,10.1013978 Z M3.52494624,3.3588172 C4.09021505,3.3588172 4.55010753,3.81860215 4.55010753,4.38387097 C4.55010753,4.94913978 4.09021505,5.40903226 3.52494624,5.40903226 C2.95967742,5.40903226 2.49989247,4.94913978 2.49989247,4.38387097 C2.49989247,3.81860215 2.95967742,3.3588172 3.52494624,3.3588172 Z M3.52494624,7.04344086 C4.41827957,7.04344086 5.17043011,7.65924731 5.37935484,8.48849462 L1.67053763,8.48849462 C1.87946237,7.65924731 2.63172043,7.04344086 3.52494624,7.04344086 Z"
												id="Shape"></path>
											<path
												d="M15.1791398,8.24795699 L17.5447312,8.24795699 C17.9902151,8.24795699 18.3511828,7.88688172 18.3511828,7.44150538 C18.3511828,6.99612903 17.9902151,6.63505376 17.5447312,6.63505376 L15.9855914,6.63505376 L15.9855914,4.10817204 C15.9855914,3.6627957 15.6246237,3.30172043 15.1791398,3.30172043 C14.7336559,3.30172043 14.3726882,3.6627957 14.3726882,4.10817204 L14.3726882,7.44150538 C14.3726882,7.88688172 14.7337634,8.24795699 15.1791398,8.24795699 Z"
												id="Path"></path>
											<path
												d="M31.1576344,8.24795699 C31.6031183,8.24795699 31.964086,7.88688172 31.964086,7.44150538 L31.964086,4.10817204 C31.964086,3.6627957 31.6031183,3.30172043 31.1576344,3.30172043 C30.7121505,3.30172043 30.3511828,3.6627957 30.3511828,4.10817204 L30.3511828,7.44150538 C30.3511828,7.88688172 30.7121505,8.24795699 31.1576344,8.24795699 Z"
												id="Path"></path>
											<path
												d="M34.0608602,8.24795699 C34.5063441,8.24795699 34.8673118,7.88688172 34.8673118,7.44150538 L34.8673118,6.10107527 L36.7070968,8.00225806 C36.862043,8.16236559 37.0723656,8.24795699 37.2868817,8.24795699 C37.3884946,8.24795699 37.4910753,8.22870968 37.5892473,8.18903226 C37.8937634,8.06580645 38.0932258,7.77010753 38.0932258,7.44150538 L38.0932258,4.10817204 C38.0932258,3.6627957 37.7322581,3.30172043 37.2867742,3.30172043 C36.8412903,3.30172043 36.4803226,3.6627957 36.4803226,4.10817204 L36.4803226,5.44849462 L34.6405376,3.54731183 C34.412043,3.31129032 34.0633333,3.23709677 33.7584946,3.36064516 C33.4539785,3.48387097 33.2545161,3.77956989 33.2545161,4.10817204 L33.2545161,7.44150538 C33.2544086,7.88688172 33.6153763,8.24795699 34.0608602,8.24795699 Z"
												id="Path"></path>
											<path
												d="M21.4764516,8.24795699 C22.8402151,8.24795699 23.9495699,7.13849462 23.9495699,5.77483871 C23.9495699,4.4111828 22.8402151,3.30172043 21.4764516,3.30172043 C20.1126882,3.30172043 19.0033333,4.4111828 19.0033333,5.77483871 C19.0033333,7.13849462 20.1126882,8.24795699 21.4764516,8.24795699 Z M21.4764516,4.91462366 C21.9507527,4.91462366 22.3366667,5.30053763 22.3366667,5.77483871 C22.3366667,6.24913978 21.9507527,6.63505376 21.4764516,6.63505376 C21.0021505,6.63505376 20.6162366,6.24913978 20.6162366,5.77483871 C20.6162366,5.30053763 21.0021505,4.91462366 21.4764516,4.91462366 Z"
												id="Shape"></path>
											<path
												d="M27.5632258,8.2188172 L27.5632258,8.62430108 C27.5632258,9.06967742 27.9241935,9.43075269 28.3696774,9.43075269 C28.8151613,9.43075269 29.176129,9.06967742 29.176129,8.62430108 L29.176129,6.95333333 C29.176129,6.6272043 28.9796774,6.33311828 28.6782796,6.20827957 C28.3768817,6.08344086 28.0301075,6.15247312 27.7994624,6.38301075 C27.464086,6.7183871 26.9184946,6.71849462 26.5829032,6.38301075 C26.2475269,6.04763441 26.2475269,5.50182796 26.5827957,5.16655914 C26.9183871,4.83129032 27.463871,4.83107527 27.7994624,5.16655914 C28.1143011,5.48150538 28.6249462,5.48139785 28.9398925,5.16655914 C29.2548387,4.8516129 29.2548387,4.34096774 28.9397849,4.02602151 C27.9755914,3.06182796 26.4065591,3.06204301 25.4422581,4.02602151 C24.4780645,4.99032258 24.4780645,6.55924731 25.4423656,7.52354839 C26.0192473,8.10043011 26.8127957,8.33193548 27.5632258,8.2188172 Z"
												id="Path"></path>
										</g>
									</g>
								</g>
							</svg>
						</span>
					</button>
				</h5>
				<div class="flip-card">
					<div [ngClass]="showPinLoginCard ? 'flip' : ''" class="flip-card__inner m-3">
						<section class="widget widget-login flip-card__front" style="height: 270px">
							<header>
								<h3>TXportal Anmeldung</h3>
							</header>
							<div class="widget-body">
								<form class="login-form mt-lg">
									<div class="form-group">
										<input #username class="form-control" id="username" placeholder="Benutzername" type="text" />
									</div>
									<div class="form-group">
										<input #password class="form-control" id="password" placeholder="Passwort" type="password" />
									</div>
									<div class="checkbox">
										<label> <input #stayloggedin type="checkbox" /> Angemeldet bleiben </label>
									</div>
									<div class="clearfix">
										<div class="btn-toolbar pull-xs-right m-t-1">
											<button
												(click)="checkLogin(username.value, password.value, stayloggedin.checked)"
												class="btn btn-primary btn-sm">
												Login
											</button>
										</div>
									</div>
									<ng-container *ngIf="mySession$ | async as Session">
										<div [hidden]="Session.statuscode === 0" class="alert alert-danger alert-sm">
											{{Session?.statustext}}
										</div>
									</ng-container>
								</form>
							</div>
						</section>
						<section *ngIf="pinModeState" class="widget pin-code flip-card__back" style="height: 420px">
							<div class="widget-body">
								<div [ngClass]="pinError ? 'miss' : ''" class="fields">
									<div class="grid">
										<div [ngClass]="{'active': pinCode.length >= 1 }" class="grid__col grid__col--1-of-4 numberfield">
											<span></span>
										</div>
										<div [ngClass]="{'active': pinCode.length >= 2 }" class="grid__col grid__col--1-of-4 numberfield">
											<span></span>
										</div>
										<div [ngClass]="{'active': pinCode.length >= 3 }" class="grid__col grid__col--1-of-4 numberfield">
											<span></span>
										</div>
										<div [ngClass]="{'active': pinCode.length >= 4 }" class="grid__col grid__col--1-of-4 numberfield">
											<span></span>
										</div>
									</div>
								</div>

								<div class="numbers">
									<div class="grid">
										<div *ngFor="let n of [1,2,3,4,5,6,7,8,9]" class="grid__col grid__col--1-of-3">
											<button (click)="clickPinButton(n)" [disabled]="!pinEnabled">{{n}}</button>
										</div>

										<div class="grid__col grid__col--1-of-3">
											<ng-container *ngIf="!(myTerminalService.qrQrPermanentCamera | async)">
												<button
													*ngIf="(myTerminalService.qrMode | async) && (myTerminalService.qrQrCamera | async)"
													(click)="showQrCamera = true">
													<i class="fa fa-qrcode"></i>
												</button>
												<p-dialog
													[(visible)]="showQrCamera"
													[responsive]="true"
													header="QR Code scannen"
													[blockScroll]="true"
													[modal]="true"
													appendTo="body">
													<tx-code-scanner
														*ngIf="showQrCamera"
														[selectedDevice]="myTerminalService.qrQrCamera | async"
														[terminalMode]="true"
														[enableDeviceSelector]="true"
														[qrScan]="true"
														[scannerEnabled]="true"
														(scanResult)="callQrLogin($event)"></tx-code-scanner>
												</p-dialog>
											</ng-container>
										</div>
										<div class="grid__col grid__col--1-of-3">
											<button (click)="clickPinButton(0)" [disabled]="!pinEnabled">0</button>
										</div>
										<div class="grid__col grid__col--1-of-3">
											<button (click)="resetPinCode()">
												<i class="fa fa-undo"></i>
											</button>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
		</div>
	</main>

	<footer class="page-footer">{{ CurrentYear }} &copy; TimeIX - Version: {{wlVersion}}</footer>
</div>
<mat-card
	*ngIf="(myTerminalService.qrQrPermanentCamera | async) && showPinLoginCard"
	class="w-22rem absolute top-0 left-0"
	[class.opacity-0]="myTerminalService.qrQrPermanentCameraHidden | async"
	cdkDrag
	[cdkDragDisabled]="!(myTerminalService.qrQrCameraPositionConfig | async)"
	(cdkDragStarted)="moveQrCamera = true;"
	(cdkDragEnded)="qrCodeDragged($event);"
	[cdkDragFreeDragPosition]="myTerminalService.qrQrCameraPosition | async">
	<mat-card-header>
		<mat-card-title class="flex flex-justify-content-space-between">
			QR Code Scanner
			<fa-icon
				*ngIf="myTerminalService.qrQrCameraPositionConfig | async"
				class="cursor-move"
				icon="arrows-alt"
				cdkDragHandle>
			</fa-icon>
		</mat-card-title>
	</mat-card-header>
	<ng-container *ngIf="!moveQrCamera; else isMoving">
		<div *ngIf="(myTerminalService.qrQrCamera | async) as camera; else noCamSelected">
			<tx-code-scanner
				*ngIf="showQrCamera"
				[selectedDevice]="camera"
				[enableDeviceSelector]="true"
                [terminalMode]="true"
				[qrScan]="true"
				[scannerEnabled]="true"
				(scanResult)="callQrLogin($event, true)">
            </tx-code-scanner>
		</div>
	</ng-container>
	<ng-template #noCamSelected>
		<h4 class="bg-danger text-white p-1">Es wurde keine Kamera konfiguriert!</h4>
	</ng-template>
	<ng-template #isMoving>
		<div class="h-15rem flex justify-content-center align-items-center">
			<fa-icon class="text-light" icon="arrows-alt" size="10x"></fa-icon>
		</div>
	</ng-template>
</mat-card>
