import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { VibrationService } from '../../shared/services/vibration.service';
import { Subject, firstValueFrom } from 'rxjs';
import { map, take, takeUntil, filter } from 'rxjs/operators';
import { BarcodeFormat, Result } from '@zxing/library';
import { AppConfigService } from '../../shared/services/appconfig.service';
import { MatSelectChange } from '@angular/material/select';
import { TerminalService } from '../../shared/services/terminal.service';

@Component({
    selector: 'tx-code-scanner',
    templateUrl: './tx-code-scanner.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class TxCodeScannerComponent implements OnInit, OnDestroy {
    @Input() scannerEnabled = true;
    @Input() playSound = true;
    @Input() vibrate = true;
    @Input() vibrationDuration = 1000;
    @Input() qrScan = false;
    @Input() formats: BarcodeFormat[] = [
        BarcodeFormat.EAN_13,
        BarcodeFormat.CODE_128,
        BarcodeFormat.QR_CODE,
        BarcodeFormat.CODE_39,
    ];
    @Input() selectedDevice: string | null = null;
    @Input() enableDeviceSelector = false;
    @Input() terminalMode = false;
    @Input() configMode = false;

    @Output() scanResult = new EventEmitter<any>();
    @Output() select = new EventEmitter<any>();

    @ViewChild('scanner', { static: true })
    scanner: ZXingScannerComponent;

    hasDevices: boolean;
    hasPermission: boolean;
    qrResult: Result;
    availablesDevices = [{ label: 'Keine Kamera gewählt', value: '-1' }];
    currentDevice: MediaDeviceInfo;
    deviceSelected = false;
    isLoading = true;
    showCameraSelect = false;

    private readonly destroy$ = new Subject();

    constructor(
        private myVibrationService: VibrationService,
        private readonly appConfig: AppConfigService
    ) {}

    handleQrCodeResult(resultString: string) {
        if (this.vibrate) {
            this.myVibrationService.vibrate(this.vibrationDuration);
        }
        this.playSuccessSound();
        this.scanResult.emit(resultString);
    }

    playSuccessSound() {
        if (this.playSound) {
            const successSound = new Audio();
            successSound.src = '../../../assets/snd/scansuccess.wav';
            successSound.load();
            successSound.play();
        }
    }

    async onDeviceSelectChange(event: MatSelectChange) {
        const selectedValue = event.value;
        const devices = await this.scanner.updateVideoInputDevices();
        const newDevice = devices.find(d => d.deviceId === selectedValue);

        if (newDevice) {
            this.currentDevice = newDevice;
            this.scanner.device = newDevice;
            this.deviceSelected = true;
            this.scannerEnabled = true;
            this.select.emit(newDevice);
            await this.appConfig.setCameraConfig({ SelectedDevice: selectedValue });

            // Warte auf die tatsächliche Device-Änderung
            await firstValueFrom(this.scanner.deviceChange.pipe(
                filter(device => device?.deviceId === selectedValue),
                take(1)
            ));
            this.selectedDevice = selectedValue;
        }
    }

    async ngOnInit() {
        this.isLoading = true;
        this.deviceSelected = false;

        if (this.terminalMode) {
            this.scanner.deviceChange
                .subscribe(async (device: MediaDeviceInfo) => {  // async hier hinzugefügt
                    if (device?.deviceId !== this.currentDevice?.deviceId) {
                        this.scanner.device = this.currentDevice;
                        this.selectedDevice = this.currentDevice.deviceId;
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        this.isLoading = false;
                        this.deviceSelected = true;
                    }
                });
        }

        this.scanner.camerasFound
            .pipe(
                map((devices: MediaDeviceInfo[]) => {
                    this.hasDevices = devices.length > 0;
                    this.availablesDevices = [{ label: 'Keine Kamera gewählt', value: '-1' }];

                    // Filter and sort devices to prioritize rear cameras
                    const rearCameras = devices.filter(device =>
                        device.label.toLowerCase().includes('back') ||
                        device.label.toLowerCase().includes('rear')
                    );

                    const otherCameras = devices.filter(device =>
                        !device.label.toLowerCase().includes('back') &&
                        !device.label.toLowerCase().includes('rear')
                    );

                    // Add all devices to the available devices list
                    [...rearCameras, ...otherCameras].forEach(device => {
                        this.availablesDevices.push({
                            label: device.label,
                            value: device.deviceId
                        });
                    });

                    return { rearCameras, otherCameras };
                }),
                take(1)
            )
            .subscribe(async ({ rearCameras, otherCameras }) => {
                try {
                    // Wenn keine Geräte gefunden wurden
                    if (!this.hasDevices) {
                        this.isLoading = false;
                        this.deviceSelected = false;
                        return;
                    }

                    // Wenn es eine Rückkamera gibt, nutze diese
                    if (rearCameras.length > 0) {
                        this.currentDevice = rearCameras[0];
                        this.showCameraSelect = false;
                    }
                    // Wenn keine Rückkamera aber andere Kameras vorhanden sind
                    else if (otherCameras.length > 0) {
                        this.showCameraSelect = true;
                        this.currentDevice = otherCameras[0];
                    }

                    // Wenn wir ein Gerät haben, setze es als aktiv
                    if (this.currentDevice) {
                        this.scanner.device = this.currentDevice;
                        this.select.emit(this.currentDevice);
                        this.deviceSelected = true;
                        await this.appConfig.setCameraConfig({
                            SelectedDevice: this.currentDevice.deviceId
                        });

                        // Warte auf die tatsächliche Device-Änderung
                        const activeDevice = await firstValueFrom(this.scanner.deviceChange.pipe(
                            filter(device => !!device),
                            take(1)
                        ));
                        this.selectedDevice = activeDevice.deviceId;
                    }
                } finally {
                    this.isLoading = this.terminalMode;
                }
            });

        this.scanner.camerasNotFound
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.hasDevices = false;
                this.deviceSelected = false;
                this.isLoading = false;
                this.showCameraSelect = false;
                this.selectedDevice = '-1';
            });

        this.scanner.permissionResponse
            .pipe(takeUntil(this.destroy$))
            .subscribe((perm: boolean) => {
                this.hasPermission = perm;
                if (!perm) {
                    this.deviceSelected = false;
                    this.isLoading = false;
                    this.showCameraSelect = false;
                    this.selectedDevice = '-1';
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
