<div [ngClass]="qrScan ? 'tx-barcode-scanner--qr' : ''" class="tx-barcode-scanner">
    <div *ngIf="enableDeviceSelector && deviceSelected && !isLoading" class="tx-barcode-scanner__select">
        <mat-form-field class="w-100" appearance="fill">
            <mat-label>Kamera</mat-label>
            <mat-select
                    [(ngModel)]="selectedDevice"
                    (selectionChange)="onDeviceSelectChange($event)"
            >
                <mat-option *ngFor="let device of availablesDevices" [value]="device.value">
                    {{ device.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div [ngClass]="deviceSelected && !isLoading ? 'tx-barcode-scanner__container--visible' : ''" class="tx-barcode-scanner__container">
        <zxing-scanner
                #scanner
                (scanSuccess)="handleQrCodeResult($event)"
                [device]="currentDevice"
                [formats]="formats"
                [autostart]="true"
                [enable]="scannerEnabled"
                [tryHarder]="true">
        </zxing-scanner>
        <div class="tx-barcode-scanner__helper"></div>
    </div>

    <div *ngIf="isLoading" class="w-100 flex flex-justify-content-center flex-align-items-center" style="min-width: 300px; min-height: 200px; padding: 10px;">
        <mat-spinner class="text-center"></mat-spinner>
    </div>

    <div *ngIf="!isLoading && !deviceSelected" class="w-100 text-center" style="padding: 10px;">
        <h3>Keine Kamera gefunden! Bitte stellen Sie sicher, dass eine Kamera angeschlossen ist und Sie die Berechtigungen erteilt haben.</h3>
    </div>
</div>
